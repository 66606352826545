import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["data-e2e"]
const _hoisted_2 = { class: "card__body" }
const _hoisted_3 = ["data-e2e"]
const _hoisted_4 = {
  key: 1,
  class: "counter"
}
const _hoisted_5 = ["data-e2e"]
const _hoisted_6 = { class: "card__footer" }

import { computed } from 'vue';
import type { OrderItem } from '@/features/orders';
import { isOrderItemRandomWeight } from '@/features/orders';
import type { TemperatureClass } from '@/features/products';
import {
  basicTemperatureClasses,
  ProductCardImage,
  ProductIcons,
} from '@/features/products';
import { CounterBox, WeightBlock } from '@/features/ui';


export default /*@__PURE__*/_defineComponent({
  __name: 'ReviewCard',
  props: {
    orderItem: {},
    disabled: { type: Boolean, default: false },
    isQuantityOriginalDisplayed: { type: Boolean, default: true }
  },
  setup(__props: any) {

const props = __props;

const getProductLabel = computed(() => {
  return (
    basicTemperatureClasses.filter((item: TemperatureClass) => {
      return item.name === props.orderItem.product.temperatureClass;
    })[0]?.label || ''
  );
});

const withCounter = computed(() => isOrderItemRandomWeight(props.orderItem));

const sku = computed<string>(() => {
  return props.orderItem.product
    ? props.orderItem.product.sku
    : props.orderItem.id;
});

const amount = computed<number>(() => {
  return parseFloat(props.orderItem.amount.toFixed(2));
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['card', { 'card--disabled-image': _ctx.disabled }]),
    "data-e2e": `review-box-${sku.value}`
  }, [
    _createVNode(_unref(ProductCardImage), {
      product: _ctx.orderItem.product,
      e2eTag: "review"
    }, null, 8, ["product"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        "data-e2e": `review-name-label-${sku.value}`,
        class: "card__title"
      }, _toDisplayString(_ctx.orderItem.product.productName), 9, _hoisted_3),
      (!_ctx.orderItem.product.rwpType && _ctx.isQuantityOriginalDisplayed)
        ? (_openBlock(), _createBlock(_unref(CounterBox), {
            key: 0,
            left: true,
            "order-item": _ctx.orderItem,
            "data-e2e": "review"
          }, null, 8, ["order-item"]))
        : _createCommentVNode("", true),
      (!_ctx.orderItem.product.rwpType && !_ctx.isQuantityOriginalDisplayed)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("span", {
              "data-e2e": `review-replacement-item-${sku.value}`,
              class: "counter__value"
            }, _toDisplayString(_ctx.orderItem.quantity), 9, _hoisted_5),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('components.review-card.items.text', { count: _ctx.orderItem.quantity })), 1)
          ]))
        : _createCommentVNode("", true),
      (withCounter.value)
        ? (_openBlock(), _createBlock(_unref(WeightBlock), {
            key: 2,
            amount: amount.value,
            "amount-original": _ctx.orderItem.amountOriginal,
            "count-weight": _ctx.orderItem.product?.rwpType === 1,
            quantity: _ctx.orderItem.quantity,
            "quantity-original": _ctx.orderItem.quantityOriginal,
            showOriginal: _ctx.isQuantityOriginalDisplayed,
            sku: _ctx.orderItem.product?.sku,
            unit: _ctx.orderItem.unit,
            left: ""
          }, null, 8, ["amount", "amount-original", "count-weight", "quantity", "quantity-original", "showOriginal", "sku", "unit"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_unref(ProductIcons), {
        name: getProductLabel.value,
        width: "32"
      }, null, 8, ["name"])
    ])
  ], 10, _hoisted_1))
}
}

})